import React from 'react';

import classNames from 'classnames';

interface NFTImageProps {
  imageModal: string;
  status?: string;
  classes?: string;
  imgClasses?: string;
  onImageClick?: () => void;
}

const NFTImage = ({ imageModal, status, classes, imgClasses, onImageClick }: NFTImageProps) => {
  return (
    <div className={classNames('relative', classes)}>
      <img
        className={classNames(
          'w-full',
          'h-auto',
          'rounded-tl-[24px]',
          ' rounded-tr-[24px]',
          onImageClick && 'hover:cursor-pointer',
          imgClasses
        )}
        src={imageModal}
        onClick={() => onImageClick?.()}
      />
      {status && (
        <p className="bg-white absolute left-0 right-0 mx-auto -bottom-[20px] text-14 leading-4 py-12 px-16 rounded-[24px] max-w-[120px] text-center border border-volcaneers-border-grey font-bold uppercase">
          {status}
        </p>
      )}
    </div>
  );
};

export default NFTImage;
