import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import * as React from 'react';

import CookieConsentBanner from '../components/CookieConsentBanner';
import Footer from '../components/v2/Footer';
import Header from '../components/v2/header/Header';
import Layout from '../components/v2/Layout';
import MyNFTsSection from '../components/v2/my_nfts/MyNFTsSection';
import { useWeb3Context } from '../hooks/useWeb3Context';

const NftCollection = () => {
  const { connected, currentAccount } = useWeb3Context();
  const { t } = useTranslation();

  return (
    <Layout title={t('My NFTs')}>
      <Header showSubHeader="volcaneers" connected={connected} currentAccount={currentAccount} />
      <CookieConsentBanner />
      <MyNFTsSection />
      <Footer withLavaIllustration connected={connected} currentAccount={currentAccount} />
    </Layout>
  );
};

export default NftCollection;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
