import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import Modal from 'react-modal';
import SimpleContent from './SimpleContent';

const customStyles: ReactModal.Styles = {
  overlay: {
    zIndex: '100',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    overflowY: 'scroll'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: 'transparent',
    border: 'none',
    marginTop: '80px'
  }
};

interface NoNFTModalProps {
  nftPrice: string;
  onCloseModal?: () => void;
  comingSoonMode?: boolean;
}

const NoNFTModal = ({ nftPrice, onCloseModal, comingSoonMode = false }: NoNFTModalProps) => {
  const [modalIsOpen, setIsOpen] = React.useState(true);
  const { t } = useTranslation();

  function closeModal() {
    setIsOpen(false);
    onCloseModal?.();
  }

  const textModal2Text = comingSoonMode ? (
    <Trans i18nKey="You can get your very own after _timestamp_!" values={{ _timestamp_: '30.11.2022, 18:00 UTC' }} />
  ) : (
    <Trans
      i18nKey="You can get your very own today for only _nftPrice_ mADA!"
      values={{
        _nftPrice_: nftPrice
      }}
    />
  );

  return (
    <div>
      <Modal isOpen={modalIsOpen} style={customStyles} contentLabel={t('No NFTs modal')} onRequestClose={closeModal}>
        <div>
          <SimpleContent
            titleModal={t('You currently do not own any Paima NFTs')}
            textModal1={t('That’s okay!')}
            textModal2={textModal2Text as unknown as string}
            imageModal="/images/modal-no-nft.png"
            urlLink="/nft-collection"
            titleButton={t('Buy NFT')}
            disabledButton={comingSoonMode}
          />
        </div>
      </Modal>
    </div>
  );
};

export default NoNFTModal;
