import { navigate } from 'gatsby';
import { Trans } from 'gatsby-plugin-react-i18next';
import React, { useEffect, useRef, useState } from 'react';
import { useLayoutContext } from '../../../hooks/useLayoutContext';

import { useWeb3Context } from '../../../hooks/useWeb3Context';
import { MyNfts } from '../../../services/types';
import { getAccountNfts } from '../../../services/utils';

import NFTCardLoader from '../NFTCardLoader';
import NoNFTModal from '../NoNFTModal';
import Pagination from '../Pagination';
import MyNFTCard from './MyNFTCard';

interface CardsGridWrapperProps {
  children: React.ReactNode;
}

const CardsGridWrapper: React.FC<CardsGridWrapperProps> = ({ children }: CardsGridWrapperProps) => {
  return <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-16 gap-y-24 mt-24">{children}</div>;
};

const MyNFTsSection = () => {
  const [myNFTS, setMyNfts] = useState<MyNfts>();
  const [pageCount, setPageCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isEmpty, setIsEmpty] = useState(false);
  const [forcedPage, setForcedPage] = useState(0);

  const { connected, currentAccount } = useWeb3Context();
  const { comingSoonMode } = useLayoutContext();

  const connectedRef = useRef<boolean | null>(null);

  useEffect(() => {
    const getMyNfts = async () => {
      if (currentAccount) {
        const data = await getAccountNfts(0, currentAccount);

        setMyNfts(data);
        setPageCount(data.response.totalPages);

        if (data.response.result.length == 0) {
          setIsLoading(false);
          setIsEmpty(true);
        } else if (data.response.result.length > 0) {
          setIsLoading(false);
          setIsEmpty(false);
        }
      }
    };
    getMyNfts();
  }, [currentAccount]);

  const redirect = () => {
    if (!connectedRef.current) {
      navigate('/');
    }
  };

  // edge-case guard for direct URL access without connected wallet
  useEffect(() => {
    const redirectTimer = setTimeout(() => {
      redirect();
    }, 1250);

    return () => clearTimeout(redirectTimer);
  }, []);

  useEffect(() => {
    connectedRef.current = connected;
  }, [connected]);

  const fetchMyNfts = async (page: number): Promise<MyNfts> => {
    const data = await getAccountNfts(page, currentAccount);

    return data;
  };

  const handlePageClick = async (page: number) => {
    const res = await fetchMyNfts(page);

    setMyNfts(res);
    setForcedPage(page);
  };

  const loadingCards = new Array(8).fill(1).map((_, index) => <NFTCardLoader key={index} />);

  return (
    <section id="my-nfts-section" className="pb-[24vw] md:pb-[14vw] xl:pb-[11vw] relative z-30">
      <div className="container-slim pt-24 md:pt-32">
        <div className="md:flex md:items-center md:justify-between">
          <h1 className="text-28 leading-7 font-bold text-black">
            <Trans>My NFTs</Trans>{' '}
            <span className="text-16 leading-7 text-volcaneers-grey-light relative -top-1">
              ({myNFTS?.response?.totalItems ?? 0})
            </span>
          </h1>
          <div className="mt-24 md:mt-0 flex justify-start">
            <Pagination handlePageClick={handlePageClick} pageCount={pageCount} forcedPage={forcedPage} />
          </div>
        </div>
        {isLoading ? (
          <CardsGridWrapper>{loadingCards}</CardsGridWrapper>
        ) : isEmpty ? (
          <>
            <CardsGridWrapper>{loadingCards}</CardsGridWrapper>
            <NoNFTModal
              comingSoonMode={comingSoonMode}
              nftPrice={`${myNFTS ? Number(myNFTS.nftPrice) : 0}`}
              onCloseModal={() => {
                navigate('/nft-collection');
              }}
            />
          </>
        ) : (
          <CardsGridWrapper>
            {myNFTS?.response.result.map((nft: any, i: any) => {
              return (
                <MyNFTCard
                  key={i}
                  index={i}
                  sequenceData={myNFTS?.response.result}
                  tokenId={nft.tokenId}
                  nftTotal={myNFTS.totalSupply}
                  nftImage={nft.metadata.image}
                />
              );
            })}
          </CardsGridWrapper>
        )}
        <div className="mt-40 flex justify-center items-center">
          <Pagination handlePageClick={handlePageClick} pageCount={pageCount} forcedPage={forcedPage} />
        </div>
      </div>
    </section>
  );
};

export default MyNFTsSection;
