import React, { createRef } from 'react';

import VolcaneersDetailModal, { VolcaneersDetailModalRefProps } from '../../Modal/VolcaneersDetailModal';
import NFTImage from '../volcaneers/modals/NFTImage';

interface MyNFTCardProps {
  nftImage: string;
  tokenId: number;
  nftTotal: string;
  index: number;
  sequenceData: any[];
}

const MyNFTCard = ({ tokenId, nftTotal, nftImage, index, sequenceData }: MyNFTCardProps) => {
  const modalRef = createRef<VolcaneersDetailModalRefProps>();

  return (
    <div className="border border-volcaneers-border-grey rounded-[24px]">
      <NFTImage imageModal={nftImage} onImageClick={() => modalRef?.current?.openModal()} />
      <div className="mt-12 md:mt-16 mb-16 px-12 md:px-16">
        <div className="flex items-center justify-center md:justify-between">
          <p className="mb-0 text-center text-grey-200 text-14 leading-5">
            #{tokenId} / {nftTotal}
          </p>
          <div className="w-[16px] h-[16px] md:w-[24px] md:h-[24px] ml-4 relative -top-1 md:top-0">
            <VolcaneersDetailModal
              ref={modalRef}
              tokenId={tokenId}
              sequenceData={sequenceData}
              index={index}
              nftTotal={nftTotal}
              disableDownload={false}
              userNftSection={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyNFTCard;
